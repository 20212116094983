<template>
  <div class="page-content">
    <page-breadcrumb title="Run Unit Tests" class="mb-2" />
    <section>
      <b-card class="active-chat">
        <form-generator :schema="form_schema" :model="form_data" />
        <loading-button :is-loading="is_running" @click="runTest" text="Run" />
      </b-card>
      <b-alert v-if="!!error">{{ error }}</b-alert>
    </section>
  </div>
</template>

<script>
import service from '../service'

const form_schema = [
  { cols: 12, fields: [{ label: 'Test Key', field: 'key', validate: { required: true } }] },
  { cols: 12, fields: [{ label: 'Params (Optional)', field: 'params' }] },
]

export default {
  data() {
    return {
      form_data: {},
      form_schema,
      is_running: false,
      error: null,
    }
  },
  methods: {
    async runTest() {
      this.is_running = true;
      let { key, params } = this.form_data;

      if(params) {
        params = JSON.parse(params);
        if(!Array.isArray(params)) {
          this.error = 'inavalid params format';
          return;
        }
      }

      let result = await service.run({ key, params: params || [] }).catch(err => {
        this.is_running = false;
      });

      this.is_running = false;
    },
  }
}
</script>
<style lang="scss">
</style>
